var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center text-h4"},[_vm._v(" How do I Clear E16, E32, or E32 error messages on my DStv Decoder? ")])])],1),_c('v-card',[_c('v-row',[_c('p',{staticClass:"px-10 pt-4"},[_vm._v(" There are three methods to clear E30, E32 & E16 errors ")]),_c('v-col',{staticClass:"px-10",attrs:{"cols":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 1. Clear Error Online using your DStv Account ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to your DStv online account "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'online',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-error-fix-1.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Click on the "),_c('code',[_vm._v("FIX NOW")]),_vm._v(" option that appears next to the exact error message on your screen. "),_c('br'),_c('em',[_vm._v(" Make sure to fix only the error displayed on your screen ")])],1)])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 2. Clear Error Using the MyDstv App ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to the MyDSTV app "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'app',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" You sould be able to see instructions on how to clear the speicif error on your screen ")],1)])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 3. Clear Error Using the DStv WhatsApp bot. ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Login to your DStv account using whatsapp "),_c('router-link',{attrs:{"to":{
                                                        query: {
                                                            method: 'whatsapp',
                                                        },
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
                                                    }}},[_vm._v("as explained here")]),_vm._v(". ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" On the DSTV whatsapp chat, reply with "),_c('code',[_vm._v("0")]),_vm._v(" and Choose "),_c('code',[_vm._v("Fix Errors on Decoder")]),_vm._v(" from the options presented. "),_c('br'),_c('em',[_vm._v(" (option 2 in this cse)")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-error-fix-2.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" You will get a reply showing the possible errors, reply with the number of the error displayed on your screen. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-error-fix-3.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" Once you reply with the number corresponding to your error message the error will be cleared and a success message will be sent to you. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-error-fix-4.jpg")}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }